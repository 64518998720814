import Link from "next/link"
import { Banner, Button } from "@components/ui"
import { accounts } from "../Social/Social"

const HomeBanner = () => {
  return (
    <Banner
      title="Try Slice, it's free"
      subtitle="Experience the future of commerce today"
      inverted
    >
      <div className="mx-auto space-y-4 max-w-screen-xs">
        <div className="pt-1">
          <Button label="Create onchain store" href="/slice" />
        </div>
        <p className="text-sm text-gray-400 opacity-90">
          <a
            className="highlight highlight-inverted"
            href={accounts.discord}
            target="_blank"
            rel="noreferrer"
          >
            Reach out
          </a>{" "}
          for questions or custom integrations
        </p>
      </div>
    </Banner>
  )
}

export default HomeBanner
