import { Button } from ".."
import { constants } from "@lib/constants"
import { Chain, formatUnits } from "viem"
import getEthFromWei from "@utils/getEthFromWei"
import { Dispatch, SetStateAction, useState } from "react"
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit"
import { availableCurrencies } from "../AddProductFormPrice/AddProductFormPrice"
import { useConfig, useWriteContract } from "wagmi"
import Slicer from "artifacts/contracts/Slicer.sol/Slicer.json"
import executeTransaction from "@utils/executeTransaction"
import { base } from "viem/chains"

type Props = {
  slicerId: number
  slicerAddress: string
  account: string
  currency: string
  unreleasedAmount: string
  setSuccess: Dispatch<SetStateAction<boolean>>
}

export const usdc =
  constants[process.env.NEXT_PUBLIC_CHAIN_ID][
    process.env.NEXT_PUBLIC_ENVIRONMENT
  ].addresses["USDCPriceFeed"]

const WithdrawButton = ({
  slicerId,
  slicerAddress,
  account,
  currency,
  unreleasedAmount,
  setSuccess
}: Props) => {
  const config = useConfig()
  const [loading, setLoading] = useState(false)
  const addRecentTransaction = useAddRecentTransaction()

  const formattedUnreleasedAmount =
    currency == usdc
      ? formatUnits(BigInt(unreleasedAmount), 6)
      : getEthFromWei(unreleasedAmount)

  const currencyName = availableCurrencies.find(
    (el) => el.value == currency
  )?.name

  const { writeContractAsync } = useWriteContract()

  return (
    <Button
      label={`Withdraw ${formattedUnreleasedAmount} ${currencyName}`}
      loading={loading}
      trackName={`withdraw_${currencyName.toLowerCase()}_to_owner`}
      onClick={async () =>
        await executeTransaction(
          config,
          async () =>
            await writeContractAsync({
              address: slicerAddress as `0x${string}`,
              abi: Slicer.abi,
              functionName: "release",
              args: [account, currency, true],
              account: account as `0x${string}`,
              chain: base as Chain
            }),
          setLoading,
          `Withdraw ${formattedUnreleasedAmount} ${currencyName} | Slicer #${slicerId}`,
          addRecentTransaction,
          () => setSuccess(true)
        )
      }
    />
  )
}

export default WithdrawButton
